<template>
  <div>
    <datetime
      type="datetime"
      v-model="date"
      class="input w-full border mt-2"
      use12-hour
    >
    </datetime>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
export default {
  components: { Datetime },
  props: {
    onChangeDate: Function,
    selectedDate: Date,
  },
  data() {
    return { date: "" };
  },

  watch: {
    date: function () {
      this.onChangeDate(this.date);
    },
    selectedDate: function () {
      this.date = this.selectedDate;
    },
  },
};
</script>

<style>
.vdatetime-input {
  background: transparent;
}
</style>